<template>
  <VContainer
    class="pa-0 position-sticky--top"
    fluid
  >
    <VRow justify="center">
      <VCol cols="auto">
        <TTBtn
          icon
          fab
          elevation="1"
          depressed
          large
          color="tt-light-mono-0"
          class="close-btn"
          data-test-label="button-backward"
          @click="$router.go(-1)"
        >
          <VIcon color="tt-light-mono-100">
            fal fa-arrow-left
          </VIcon>
        </TTBtn>
        <div
          class="tt-light-mono-46--text mt-2 tt-text-footnote text-center"
        >
          {{ $t('button_text.backward') }}
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'BackwardButton',
};
</script>

<style scoped>

</style>
